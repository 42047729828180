footer {
  margin-top: 61px;

  .company-details {
    background-color: #4CAD34;
    color: #fff;
    padding: 44px 0;

    .container {
      display: flex;
      justify-content: center;
      flex-direction: column;

      @media screen and (min-width: $lg) {
        flex-direction: row;
      }
    }

    a {
      color: #fff;
      margin-top: 10px;
      padding-left: 35px;
      font-family: 'Roboto', sans-serif;
      font-size: 20px;
      text-decoration: none;

      @media screen and (min-width: $lg) {
        margin-left: 28px;
        margin-top: 0;
      }
    }

    .location {
      background-image: url("../images/icon-location.svg");
      background-position: 0 2px;
      background-repeat: no-repeat;
      padding-left: 35px;
    }

    .phone {
      background-image: url("../images/icon-phone.svg");
      background-position: 0 2px;
      background-repeat: no-repeat;
    }

    .email {
      background-image: url("../images/icon-email.svg");
      background-position: 0 3px;
      background-repeat: no-repeat;
    }
  }

  .links {
    background-color: #1F1F1F;
    padding: 42px 0 30px 0;

    .container {
      display: flex;
      justify-content: space-between;
    }

    .site-logo {
      margin: 0;
    }

    .bottom-menu {
      display: flex;
      align-items: center;

      ul {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        list-style-type: none;
        padding-left: 0;
        margin-bottom: 0;

        li {
          margin-left: 20px;
          margin-bottom: 7px;

          @media screen and (min-width: $md) {
            margin-bottom: 0;
            margin-left: 45px;
          }

          a {
            color: #fff;
            font-family: 'Roboto', sans-serif;
            font-size: 16px;
            font-weight: 700;
            text-decoration: none;

            &:hover {
              color: #4CAD34;
            }
          }
        }
      }
    }
  }

  .cookies {
    background-color: #1F1F1F;
    text-align: center;

    .container {
      border-top: 1px solid rgba(255, 255, 255, 0.20);
      padding: 42px 2% 30px 2%;
    }

    p {
      color: #fff;
      text-align: center;
      font-family: 'Roboto', sans-serif;
      font-size: 12px;
      font-weight: 400;
    }

    a {
      color: #4CAD34;
      font-family: 'Roboto Condensed', sans-serif;
      font-size: 12px;
      font-weight: 700;
    }
  }
}

#lift {
  align-items: center;
  background-color: #F0F0F0;
  border: none;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  text-decoration: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  padding: 0;

  span {
    background-image: url('../images/arrow-up.svg');
    background-repeat: no-repeat;
    background-size: 30px 30px;
    height: 30px;
    width: 30px;
  }

  &:focus {
    outline-offset: 0;
    outline: 1px solid #4CAD34;
  }
}