.pagination {
  border: none;
  display: flex;
  justify-content: center;
}
.wp-pagenavi {
  display: flex;
  .pages {
    display: none;
  }

  span.current {
    border: 2px solid #4CAD34;
    color: #4CAD34;
    border-radius: 15px;
    display: inline-block;
    font-size: 15px;
    font-weight: bold;
    height: 43px;
    line-height: 38px;
    margin: 0 5px;
    text-align: center;
    width: 40px;
  }

  a {
    border: 2px solid #8586881A;
    border-radius: 15px;
    display: inline-block;
    font-size: 15px;
    font-weight: bold;
    height: 43px;
    line-height: 38px;
    margin: 0 5px;
    text-align: center;
    width: 40px;

    &:hover {
      border-color: #4CAD34;
      text-decoration: none;
    }
  }
}