.error-404 {
  margin: 0;

  input[type=submit]:not(:hover):not(:active):not(.has-background) {
    background-color: #fff;
    border: 3px solid #4CAD34;
    color: #4CAD34;
  }

  input[type=submit]:hover {
    background-color: #4CAD34;
    color: #fff;
  }
}
