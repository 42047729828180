.header-top {
  background-color: #4CAD34;
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 0;

  .container {
    display: flex;
    justify-content: flex-end;

    @media screen and (min-width: $xs) {
      justify-content: space-between;
    }
  }

  .contact {
    display: none;

    @media screen and (min-width: $xs) {
      display: block;
    }

    .text {
      display: none;
      color: var(--BIAY, #FFF);
      margin-right: 20px;

      @media screen and (min-width: $md) {
        display: inline-block;
        height: 20px;
        margin-right: 11px;
      }

      @media screen and (min-width: $lg) {
        background-image: url("../images/icon-location.svg");
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: contain;
        margin-right: 20px;
        padding-left: 27px;
      }
    }

    a {
      background-image: url("../images/icon-phone.svg");
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: contain;
      color: #fff;
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 29px;
      padding-left: 20px;
      text-decoration: none;

      @media screen and (min-width: $lg) {
        padding-left: 25px;
      }
    }
  }

  .right {
    display: flex;

    ul {
      display: flex;
      list-style-type: none;
      padding-left: 0;
      margin-bottom: 0;

      li {
        border: 2px solid rgba(255, 255, 255, .3);
        border-radius: 8px;
        margin-left: 8px;

        &:hover {
          border: 2px solid #fff;
        }

        &.active {
          border: 2px solid #fff;
        }

        a {
          color: #fff;
          align-items: center;
          display: flex;
          font-weight: 8000;
          height: 25px;
          justify-content: center;
          text-decoration: none;
          text-transform: uppercase;
          width: 25px;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}
.wrapper-menu .container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;

  .site-logo {
    margin: 30px 0;

    @media screen and (min-width: $lg) {
      margin: 25px 0 25px 19px;
    }
  }
}

.nav-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  display: none;
  overflow: hidden;

  @media screen and (min-width: $lg) {
    display: block;
    position: relative;
    overflow: unset;
    margin-right: 20px;
  }

  nav {
    background-color: #fff;
    width: 350px;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding-top: 136px;

    @media screen and (min-width: $lg) {
      align-items: center;
      display: flex;
      justify-content: end;
      margin: 30px 0;
      padding-top: 0;
      position: relative;
      width: unset;
    }

    ul {
      list-style-type: none;
      margin-left: 0;
      margin-bottom: 0;

      @media screen and (min-width: $lg) {
        display: flex;
        padding-top: 0;
      }

      li {
        margin-bottom: 8px;

        @media screen and (min-width: $lg) {
          margin-bottom: 0;
          margin-left: 45px;
        }

        &.current-menu-item a {
          color: #4CAD34;
        }

        &:hover {
          a {
            color: #4CAD34;
          }

          li a {
            color: #858688;

            &:hover {
              color: #4CAD34;
            }
          }
        }

        @media screen and (min-width: $lg) {

          &.menu-item-has-children {
            position: relative;

            &:after {
              background: url("../images/arrow.svg") 0 0 no-repeat;
              display: inline-block;
              content: '';
              height: 7px;
              width: 9px;
            }

            &:hover {
              .sub-menu {
                display: block;
              }
            }
          }
        }

        a {
          color: #858688;
          font-size: 20px;
          font-weight: 700;
          text-decoration: none;

          @media screen and (min-width: $lg) {
            font-size: 16px;
          }

          &:hover {
            text-decoration: none;
          }
        }
      }

      .sub-menu-toggle {
        display: none;
      }

      @media screen and (min-width: $lg) {
        .sub-menu {
          background-color: #fff;
          display: none;
          padding-left: 15px;
          padding-right: 20px;
          padding-bottom: 5px;
          position: absolute;
          margin-bottom: 0;
          width: 200px;

          li {
            margin-left: 0;

            a {
              display: inline-block;
              font-size: 14px;
              padding: 3px 0;
            }
          }
        }
      }
    }
  }
}


#menu-button {
  background-color: #fff;
  background-image: url("../images/icon-burger.png");
  background-repeat: no-repeat;
  background-position: 0 0;
  height: 40px;
  width: 66px;

  @media screen and (min-width: $lg) {
    display: none;
  }

  &.close {
    background-image: url("../images/icon-close.png");
    position: absolute;
    right: 12px;
    z-index: 1000;
  }

  &:hover {
    border-color: #fff;
  }
}

.site-logo .custom-logo {
  max-height: inherit;
  max-width: 100%;
}

@media screen and (min-width: $lg) {
  header {
    height: 141px;

    .wrapper-header {
      position: fixed;
      width: 100%;
      background: #fff;
      z-index: 100;
    }
  }
}
