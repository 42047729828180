#contact-form {
  label {
    color: #281615;
    display: block;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 7px;

    span {
      color: #FF1900;
    }
  }

  input {
    border-radius: 0 15px;
    border: 1px solid #FFF;
    background: rgba(240, 240, 240, 0.60);
    font-size: 15px;
    height: 53px;
    padding: 0 2%;
    width: 100%;
  }

  textarea {
    border-radius: 0 15px;
    border: 1px solid #FFF;
    background: rgba(240, 240, 240, 0.60);
    font-size: 14px;
    padding: 12px 2%;
    width: 100%;
  }

  .field {
    margin-bottom: 15px;
  }

  .form-error {
    color: #FF1900;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-weight: 400;
  }

  .message-error {
    border-radius: 15px;
    background: #FF1900;
    color: #FFF;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 25px;
    margin-bottom: 25px;
    padding: 20px;
  }

  .message-success {
    border-radius: 15px;
    background: #4CAD34;
    color: #FFF;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 25px;
    margin-bottom: 25px;
    padding: 20px;
  }

  button {
    background-color: #fff;
    border-radius: 15px;
    border: 2px solid #4CAD34;
    color: #4CAD34;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 100%;

    @media screen and (min-width: $lg) {
      width: 60%;
    }

    &:disabled,
    &[disabled] {
      background-color: #858688;
      cursor: not-allowed;

      &:hover {
        background-color: #858688;
      }
    }

    .loader {
      border: 10px solid #4CAD34;
      border-top: 10px solid #fff;
      border-radius: 50%;
      display: none;
      width: 22px;
      height: 22px;
      margin: 0 auto;
      -webkit-animation: spin 2s linear infinite;
      animation: spin 2s linear infinite;
    }

    &:hover {
      background-color: #4CAD34;
      color: #fff;

      span:after {
        background-image: url("../images/arrow-white.svg");
      }
    }

    span:after {
      background-image: url("../images/arrow-green.svg");
      background-position: 9px 0;
      background-repeat: no-repeat;
      content: '';
      display: inline-block;
      height: 9px;
      width: 23px;
    }
  }
}

@keyframes spin {

  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

#chooseFile {
  display: none;

  &.show {
    display: block;
  }
}

#contact-form button .show {
  display: flex;
}

#contact-form button .hide {
  display: none;
}

#contact-form input[type=file] {
  background: transparent;
  padding: 0;
}

input[type=file]::file-selector-button {
  margin-right: 20px;
  border: none;
  background: #4CAD34;
  padding: 17px 20px;
  border-radius: 0 15px;
  color: #fff;
  cursor: pointer;
  transition: background .2s ease-in-out;
}

input[type=file]::file-selector-button:hover {
  background: #389421;
}
