@import "utils/reset";
@import "utils/grid";
@import "utils/font";
@import "utils/global";
@import "variables";
@import "blocks/header";
@import "blocks/footer";
@import "blocks/blog-list";
@import "blocks/our-team";
@import "blocks/contact";
@import "blocks/offers";
@import "pages/home";
@import "pages/about";
@import "pages/contact";
@import "pages/offer";
@import "pages/blog";
@import "pages/offers-job";
@import "pages/single";
@import "blocks/magnific-popup";
@import "blocks/404";
@import "blocks/gallery";
@import "blocks/pagination";
