.blog {
  margin-bottom: 60px;

  h1 {
    position: relative;
    padding-left: 20px;

    &:before {
      display: inline-block;
      border-top-left-radius: 10px;
      border-bottom-right-radius: 10px;
      content: '';
      height: 55px;
      width: 9px;
      background-color: #4CAD34;
      position: absolute;
      left: 0;
    }
  }


  ul {
    list-style-type: none;
    padding-left: 0;

    li {
      border-radius: 25px 0;
      border: 2px solid rgba(133, 134, 136, 0.10);
      padding: 25px 46px 25px 26px;
      margin-bottom: 15px;

      &:hover {
        border-color: #4CAD34;
      }

      a {
        text-decoration: none;
      }

      .post-wrapper {
        display: flex;
        flex-direction: column;

        @media screen and (min-width: $lg) {
          flex-direction: row;
        }

        img {
          border-bottom-right-radius: 20px;
          border-top-left-radius: 20px;
          margin: 0 auto;

          @media screen and (min-width: $lg) {
            margin: 0;
          }
        }
      }

      .details-post {
        display: flex;
        margin-top: 25px;
        flex-direction: column;
        justify-content: space-between;

        @media screen and (min-width: $lg) {
          margin-left: 34px;
        }

        .post-date {
          color: rgba(133, 134, 136, 0.50);
          font-family: 'Roboto', sans-serif;
          font-size: 14px;
          font-weight: 600;
          line-height: 15px;
          margin-bottom: 17px;
        }

        h2 {
          color: #000;
          font-family: 'Roboto', sans-serif;
          font-size: 23px;
          font-weight: 600;
          line-height: 25px;
          margin-bottom: 5px;
        }

        .more {
          display: inline-block;
        }
      }
    }
  }
}

//single article
.post-navigation .nav-links {
  clear: both;
  display: inline-block;
  width: 100%;

  .nav-previous {
    float: left;
  }

  .nav-next {
    float: right;
  }

  a {
    text-decoration: none;

    &:hover {
      .meta-nav {
        color: #4CAD34;
      }
    }
  }
}
