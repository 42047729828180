.our-team {
  background: #4CAD34;
  border-radius: 80px 0;
  padding: 45px 10% 65px 8%;
  margin-bottom: 62px;

  @media screen and (min-width: $lg) {
    padding: 45px 78px 65px 73px;
  }

  h2 {
    color: #fff;
    margin-bottom: 45px;
    text-align: center;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 22px;
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;

    li {
      border-radius: 0 25px;
      border: 2px solid rgba(255, 255, 255, 0.20);
      display: flex;
      flex-direction: column;
      width: 100%;

      @media screen and (min-width: $xs) {
        flex-flow: row wrap;
      }

      @media screen and (min-width: $lg) {
        width: 48%;
      }

      @media screen and (min-width: $xxl) {
        width: 32%;
      }

      &:first-child {
        img {
          border-radius: 0 25px 0 25px;
        }
      }

      img {
        border-radius: 0 25px 0 0;
        flex: 1 0 0;
        max-width: 166px;
      }

      .parson-description {
        flex: 1 0 0;

        .details {
          margin: 0 25px 0 17px;
          padding-bottom: 16px;

          @media screen and (min-width: $md) {
            height: 116px;
          }

          p, span {
            color: #fff;
            font-family: 'Roboto Condensed', sans-serif;
          }

          p {
            font-size: 26px;
            font-weight: 500;
            margin: 11px 0 8px 0;

            @media screen and (min-width: $md) {
              margin: 11px 0 18px 0;
            }
          }

          span {
            display: inline-block;
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
          }
        }

        .phones {
          border-top: 2px solid rgba(255, 255, 255, 0.20);
          display: flex;
          flex-direction: column;
          padding: 15px 0 15px 16px;

          @media screen and (min-width: $md) {
            padding: 15px 0 0 16px;
          }

          a {
            color: #fff;
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            text-decoration: none;

            &.mobile {
              background-image: url("../images/icon-mobile.svg");
              background-position: 0 0;
              background-repeat: no-repeat;
              background-size: 18px 18px;
              padding-left: 24px;
              margin-bottom: 8px;

              @media screen and (min-width: $md) {
                margin-bottom: 0;
              }
            }

            &.phone {
              background-image: url("../images/icon-phone.svg");
              background-position: 0 0;
              background-repeat: no-repeat;
              background-size: 18px 18px;
              padding-left: 24px;

              @media screen and (min-width: $md) {
                margin-top: 7px;
              }
            }
          }
        }
      }

      .email {
        border-top: 2px solid rgba(255, 255, 255, 0.20);
        flex: 1 100%;
        text-align: center;
        padding: 15px 0;

        @media screen and (min-width: $md) {
          padding: 6px 0 8px 0;
        }

        a {
          background-image: url("../images/icon-email.svg");
          background-position: 0 0;
          background-repeat: no-repeat;
          background-size: 18px 18px;
          color: #fff;
          font-family: 'Roboto', sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          padding-left: 26px;
          text-decoration: none;
        }
      }
    }
  }
}
