.contact-page {
  margin-bottom: 60px;

  @media screen and (min-width: $lg) {
    display: flex;
    flex-flow: row wrap;
  }

  h1 {
    flex: 1 100%;
    margin-bottom: 25px;
    position: relative;
    padding-left: 20px;

    &:before {
      display: inline-block;
      border-top-left-radius: 10px;
      border-bottom-right-radius: 10px;
      content: '';
      height: 55px;
      width: 9px;
      background-color: #4CAD34;
      position: absolute;
      left: 0;
    }
  }

  .left {
    border-top: 2px solid rgba(133, 134, 136, 0.10);
    flex: 1 0 0;
    margin-right: 30px;

    .location, .work-hours {
      border-bottom: 2px solid rgba(133, 134, 136, 0.10);
      padding: 37px 0 37px 32px;

      p {
        color: #000;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        margin-bottom: 5px;

        &.bold {
          font-weight: 700;
          text-transform: uppercase;
        }
      }
    }

    .location {
      background-image: url("../images/icon-pin.svg");
      background-position: 0 39px;
      background-repeat: no-repeat;
    }

    .work-hours {
      background-image: url("../images/icon-clock.svg");
      background-position: 0 39px;
      background-repeat: no-repeat;
    }

    .text {
      padding-top: 32px;
    }
  }

  .contact-form {
    flex: 1 0 0;
  }
}
