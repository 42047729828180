.blog-list {
  display: flex;
  flex-flow: column;

  @media screen and (min-width: $lg) {
    flex-flow: row wrap;
    padding: 0 19px 0 20px;
  }

  .article {
    border-radius: 25px 0;
    border: 2px solid rgba(133, 134, 136, 0.10);
    margin-bottom: 60px;
    padding: 24px 24px 60px 28px;
    position: relative;
    flex: 1 0 0;
    text-decoration: none;

    &.margin-left {
      margin-left: 0;
    }

    @media screen and (min-width: $lg) {
      margin-bottom: 0;
      margin-left: 20px;
    }

    &:hover {
      border: 2px solid #4CAD34;
    }

    &.first {
      margin-left: 0;
    }

    .image {

      img {
        border-radius: 15px 0;
        margin-bottom: 20px;

        @media screen and (min-width: $lg) {
          max-width: 100%;
          max-height: 233px;
        }
      }
    }



    .data {
      color: rgba(133, 134, 136, 0.50);
      text-align: center;
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 17px;
    }

    .title {
      color: #000;
      text-align: center;
      font-family: 'Roboto', sans-serif;
      font-size: 23px;
      font-weight: 600;
      line-height: 25px;
    }
    .button-wrapper {
      position: absolute;
      bottom: -27px;
      width: 85%;
      display: block;
      margin: 0 auto;
      background: #fff;
    }

    .more {
      margin: 0 auto;
      text-align: center;
      width: 80%;

      @media screen and (min-width: $md) {
        padding:  13px 0;
      }
    }
  }

  hr {
    border-bottom: 2px solid rgba(133, 134, 136, 0.10);
    flex: 1 100%;
    margin-top: 86px;
  }

  .button-all-wrapper {
    background-color: #fff;
    margin: -20px auto 0 auto;
    padding: 0 31px;
  }

  .all {
    border-radius: 15px;
    border: 2px solid rgba(133, 134, 136, 0.10);
    color: #4CAD34;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 15px;
    font-weight: 700;
    flex: 1 100%;
    margin-top: 41px;
    padding: 14px 55px;
    text-decoration: none;

    &:hover {
      background-color: #4CAD34;
      color: #fff;
    }
  }
}
