h1 {
  color: #1F1F1F;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 34px;
  font-weight: 700;
  line-height: 42px;
  margin-bottom: 30px;
  flex: 1 100%;
  position: relative;
  padding-left: 20px;

  @media screen and (min-width: $lg) {
    font-size: 50px;
    line-height: 55px;
  }

  &:before {
    display: inline-block;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    content: '';
    height: 55px;
    width: 9px;
    background-color: #4CAD34;
    position: absolute;
    left: 0;
  }
}

h2 {
  color: #1F1F1F;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 25px;

  @media screen and (min-width: $lg) {
    font-size: 41px;
    line-height: 55px;
  }
}

h3 {
  color: #1F1F1F;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 26px;
  font-weight: 700;
  line-height: 26px;
  margin-bottom: 22px;
}

h4 {
  color: #1F1F1F;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 26px;
  margin-bottom: 20px;
}

h5 {
  color: #1F1F1F;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 23px;
  font-weight: 700;
  line-height: 26px;
  margin-bottom: 18px;
}

h6 {
  color: #1F1F1F;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 16px;
}

p {
  color: #6E6E6E;
  font-family: 'Roboto', sans-serif;;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 20px;
}

ul, ol {
  font-family: 'Roboto', sans-serif;;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  padding-left: 20px;
  margin-bottom: 20px;
}

a {
  color: #4CAD34;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.more {
  background-color: #fff;
  border-radius: 15px;
  border: 2px solid #4CAD34;
  color: #4CAD34;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 15px;
  font-weight: 700;
  padding: 13px 58px;
  text-decoration: none;

  &:hover {
    background-color: #4CAD34;
    color: #fff;
    text-decoration: none;

    span:after {
      background-image: url("../images/arrow-white.svg");
    }
  }

  span:after {
    background-image: url("../images/arrow-green.svg");
    background-position: 9px 0;
    background-repeat: no-repeat;
    content: '';
    display: inline-block;
    height: 9px;
    width: 23px;
  }
}
