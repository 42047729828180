.about-us {
  margin-bottom: 55px;

  @media screen and (min-width: $lg) {
    display: flex;
    flex-flow: row wrap;
    gap: 20px;
  }

  .left {
    flex: 1 0 0;

    h1 {
      margin-bottom: 23px;
    }
  }

  .right {
    list-style-type: none;
    flex: 1 0 0;
    padding-left: 0;
    margin-bottom: 0;

    li {
      margin-bottom: 20px;

      &:first-child {
        img {
          border-radius: 0 80px 0 0;
        }
      }

      &:last-child {
        img {
          border-radius: 0 0 0 80px;
        }
      }
    }

    img {
      max-width: 100%;
    }
  }
}
