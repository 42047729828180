.offer {
  .offer-page {
    margin-bottom: 60px;

    @media screen and (min-width: $lg) {
      display: flex;
      flex-flow: row wrap;
    }

    h1 {
      align-items: center;
      display: flex;
      flex: 1 100%;
      margin-bottom: 25px;
      padding-left: 0;
      word-break: break-word;

      &:before {
        display: none;
      }

      img {
        margin-right: 17px;
      }
    }

    h2 {
      color: #4CAD34;
    }

    ul {
      padding-left: 0;
      margin-bottom: 0;

      li {
        color: #6E6E6E;
        list-style: none;

        &:before {
          border-radius: 0 3px;
          background: #4CAD34;
          content: '';
          display: inline-block;
          height: 7px;
          margin: 0 10px 3px 0;
          width: 7px;
        }
      }
    }
  }

  .gallery {
    list-style-type: none;
    margin-bottom: 60px;
    padding: 0;

    @media screen and (min-width: $lg) {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }

    li {
      margin-bottom: 20px;

      @media screen and (min-width: $lg) {
        margin-bottom: 0;
        width: 31%;
      }

      @media screen and (min-width: $xxl) {
        width: auto;
      }

      &:first-child {
        img {
          border-radius: 80px 0 0 0;
        }
      }

      &:last-child {
        img {
          border-radius: 0 0 80px 0;
        }
      }
    }

    img {
      height: auto;
      max-width: 100%;

      @media screen and (min-width: $lg) {
        height: 238px;
        width: 294px;
      }
    }
  }
}
