.section-singular-header {
  .singular-header-image-mobile {
    @media screen and (min-width: $lg) {
      display: none;
    }
  }

  .singular-header-image {
    display: none;

    @media screen and (min-width: $lg) {
      display: block;
      margin-right: 40px;

      img {
        border-bottom-right-radius: 80px;
        border-top-left-radius: 80px;
      }
    }
  }

  .container {
    @media screen and (min-width: $md) {
      display: flex;
    }

    .singular-header-content {
      margin-top: 20px;

      @media screen and (min-width: $md) {
        margin-left: 30px;
      }

      h1 {
        margin-bottom: 10px;
      }

      .singular-header-date p {
        color: rgba(133, 134, 136, 0.5);
      }

      .singular-header-info {
        align-items: center;
        display: flex;
        margin-top: 10px;

        .singular-info-photo {
          align-items: center;
          border: 1px solid #4CAD34;
          border-bottom-right-radius: 10px;
          border-top-left-radius: 10px;
          display: flex;
          justify-content: center;
          height: 40px;
          width: 40px;

          img {
            max-width: 25px;
          }
        }

        p {
          margin: 0 0 0 10px;
        }
      }
    }
  }
}

.section-singular-content {
  margin-top: 60px;
}