.home {
  .partner {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;

    @media screen and (min-width: $md) {
      flex-direction: row;
    }

    .img-wrapper {
      @media screen and (min-width: $md) {
        flex: 0 0 50%;
        margin-right: 20px;
      }

      @media screen and (min-width: $xxl) {
        margin-right: 0;
      }

      img {
        max-width: 100%;
        border-bottom-left-radius: 80px;
        margin: 0 auto;
      }
    }

    .text {
      .partner-header {
        display: flex;
        flex-flow: row;
        align-items: center;
        margin: 20px 0;

        @media screen and (min-width: $md) {
          align-items: flex-start;
          margin: 10px 0 24px 0;
        }

        .icon-wrapper {
          display: flex;
          align-items: center;
          border: 1px solid #4CAD34;
          border-radius: 50%;
          height: 61px;
          justify-content: center;
          width: 61px;
        }

        h1 {
          color: #1F1F1F;
          font-family: 'Roboto Condensed', sans-serif;
          font-size: 30px;
          font-weight: 700;
          line-height: 35px;
          margin-bottom: 0;
          margin-left: 16px;
          padding-left: 0;

          &:before {
            display: none;
          }

          @media screen and (min-width: $lg) {
            font-size: 50px;
            line-height: 55px;
          }
        }
      }



      p {
        color: #6E6E6E;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        margin-bottom: 40px;
      }
    }
  }

  .certificates {
    border-radius: 80px 0;
    background: #4CAD34;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 60px;

    @media screen and (min-width: $lg) {
      flex-direction: row;
    }

    .certificates-bg {
      align-items: center;
      background-image: url("../images/certificates-bg.png");
      background-repeat: no-repeat;
      border-radius: 80px 0;
      display: flex;
      padding: 95px 19px 65px 19px;

      @media screen and (min-width: $md) {
        padding: 95px 0 95px 6%;
      }

      @media screen and (min-width: $lg) {
        padding: 125px 0 125px 119px;
      }

      .wrapper-certificates-icon {
        align-items: center;
        border: 1px solid rgba(255, 255, 255, .4);
        border-radius: 50%;
        display: none;
        justify-content: center;
        height: 156px;
        width: 156px;

        @media screen and (min-width: $md) {
          display: flex;
        }
      }
    }

    .text {
      text-align: center;
      width: 100%;

      @media screen and (min-width: $md) {
        text-align: left;
        margin-left: 20px;
      }
    }

    h3 {
      color: #fff;
      font-family: 'Roboto Condensed', sans-serif;
      font-size: 31px;
      font-weight: 700;
      line-height: 36px;
      margin-bottom: 11px;

      @media screen and (min-width: $md) {
        font-size: 50px;
        line-height: 46px;
      }
    }

    a.link {
      color: #fff;
      display: inline-block;
      font-family: 'Roboto', sans-serif;
      font-size: 19px;
      line-height: 20px;

      &:hover {
        text-decoration: none;
      }
    }

    ul {
      display: flex;
      justify-content: center;
      list-style-type: none;
      padding: 0 25px 40px 25px;

      @media screen and (min-width: $lg) {
        margin: 100px 58px 0 0;
      }

      @media screen and (min-width: $xl) {
        justify-content: end;
        margin: 100px 78px 0 0;
        padding: 0;
      }

      li {
        &:first-child {
          margin-right: 25px;

          @media screen and (min-width: $md) {
            margin-right: 45px;
          }

          @media screen and (min-width: $lg) {
            margin-right: 25px;
          }
        }
        @media screen and (min-width: $xl) {
          margin-left: 60px;
        }

        img {
          max-width: 100%;
        }
      }
    }
  }
}
