.offers-job {
  h1 {
    flex: 1 100%;
    position: relative;
    padding-left: 20px;

    &:before {
      display: inline-block;
      border-top-left-radius: 10px;
      border-bottom-right-radius: 10px;
      content: '';
      height: 55px;
      width: 9px;
      background-color: #4CAD34;
      position: absolute;
      left: 0;
    }
  }

  h2 {
    color: #4CAD34;
  }

  .offers-list {
    display: flex;
    flex-flow: row wrap;
    gap: 20px;
    margin-top: 40px;

    .job-box {
      border-radius: 25px 0;
      border: 2px solid rgba(133, 134, 136, 0.10);
      padding: 48px 42px 60px 42px;

      @media screen and (min-width: $md) {
        width: 48%;
      }

      @media screen and (min-width: $xl) {
        width: 49%;
      }

      .job-header {
        margin-bottom: 22px;

        h3 {
          color: #4CAD34;
          font-family: 'Roboto', sans-serif;
          font-size: 23px;
          font-style: normal;
          font-weight: 600;
          line-height: 35px;

          span {
            color: rgba(133, 134, 136, 0.50);
            font-weight: 400;
          }
        }

        p {
          color: #000;
          font-family: 'Roboto', sans-serif;
          font-size: 23px;
          font-style: normal;
          font-weight: 600;
          line-height: 35px;
          margin-bottom: 0;

          span {
            color: rgba(133, 134, 136, 0.50);
            font-weight: 400;
          }
        }
      }

      .job-details {
        margin-bottom: 40px;

        p {
          font-weight: 600;
          margin-bottom: 0;
        }

        ul {
          margin-bottom: 15px;
          padding-left: 0;

          li {
            list-style: none;

            &:before {
              border-radius: 0 3px;
              background: #4CAD34;
              content: '';
              display: inline-block;
              height: 7px;
              margin: 0 10px 3px 0;
              width: 7px;
            }
          }
        }
      }
    }
  }
}
