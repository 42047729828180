.offers {
  background-color: #4CAD34;
  border-bottom-right-radius: 80px;
  border-top-left-radius: 80px;
  display: flex;
  flex-flow: column;
  column-gap: 20px;
  margin-bottom: 50px;
  padding: 40px 25px 60px 25px;

  @media screen and (min-width: $md) {
    flex-flow: row wrap;
  }

  @media screen and (min-width: $lg) {
    flex-flow: row wrap;
    padding: 40px 25px 60px 25px;
  }

  h2 {
    color: #fff;
    flex: 1 100%;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 31px;
    font-style: normal;
    font-weight: 700;
    line-height: 45px;
    margin-bottom: 32px;
    padding: 0 3%;
    text-align: center;

    @media screen and (min-width: $md) {
      padding: 0;
    }

    @media screen and (min-width: $lg) {
      font-size: 41px;
      line-height: 55px;
    }
  }

  .box-offer {
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    justify-content: space-between;
    margin-bottom: 60px;

    @media screen and (min-width: $lg) {
      margin-bottom: 0;
    }

    h3 {
      color: #fff;
      font-family: 'Roboto Condensed', sans-serif;
      text-align: center;
      font-size: 25px;
      font-weight: 500;
      margin-bottom: 10px;
      text-transform: uppercase;

      @media screen and (min-width: $md) {
        font-size: 19px;
      }

      @media screen and (min-width: $lg) {
        font-size: 15px;
      }

      @media screen and (min-width: $xl) {
        font-size: 18px;
      }

      @media screen and (min-width: $xxl) {
        font-size: 21px;
      }
    }

    img {
      border-bottom-left-radius: 15px;
      border-top-right-radius: 15px;
      margin-bottom: 15px;
      max-width: 100%;
    }

    p {
      color: #fff;
      text-align: center;
      font-family: 'Roboto', sans-serif;
      font-size: 15px;
      font-weight: 400;
      line-height: 21px;
      margin-bottom: 22px;
    }

    a {
      border-radius: 15px;
      border: 2px solid #fff;
      color: #fff;
      display: block;
      font-family: 'Roboto Condensed', sans-serif;
      font-size: 15px;
      font-weight: 700;
      padding: 13px 0;
      text-align: center;
      text-decoration: none;

      span:after {
        background-image: url("../images/arrow-white.svg");
        background-position: 9px 0;
        background-repeat: no-repeat;
        content: '';
        display: inline-block;
        height: 9px;
        width: 23px;
      }

      &:hover {
        background-color: #fff;
        color: #4CAD35;

        span:after {
          background-image: url("../images/arrow-green.svg");
        }
      }
    }
  }
}
